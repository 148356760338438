@font-face {
  font-family: Nunito-Regular;
  src: url("./assets/fonts/Nunito-Regular.ttf");
}
@font-face {
  font-family: Nunito-Bold;
  src: url("./assets/fonts/Nunito-Bold.ttf");
}
@font-face {
  font-family: Nunito-SemiBold;
  src: url("./assets/fonts/Nunito-SemiBold.ttf");
}
body {
  background: #F7FAFC;
  font-family: Nunito-Regular;
  color: #4D4D4D;
}
select {
  font-family: Nunito-Regular;
  text-align: center;
  font-size: 18px;
  border-radius:10px;
  border:0.5px solid #AAAAAA;
  padding: 8px;
}

h1 {
  font-size: 36px;
  color: #EE4D2D;
  font-family: Nunito-Bold;
  text-align: center;
}
h2 {
  padding-left: 0%;
  font-size: 24px;
  margin-left: 20px;
  font-weight: 500;
  font-family: Nunito-SemiBold;
  text-align: left;
}

h3 {
  font-size: 24px;
  margin-left: 20px;
  font-weight: 500;
  font-family: Nunito-SemiBold;
  text-align: left;
}

h4 {
  font-size: 18px;
  font-family: Nunito-Bold;
  text-align: center;
  margin-top: 0;
}

a {
  display: inline-block;
  font-size: 18px;
  color: #EE4D2D;
  font-family: Nunito-Bold;
  margin-left: 10px;
}

option {
  border-radius:10px;
}
.td-label {
  padding-right: 55px; 
  font-size: 13px;
}

.error {
  color: #EE4D2D;
  font-size: 20px;
  font-weight: bolder;
}
.td-value {
  font-size: 13px;
  word-break: break-all; 
}

.td-deductions {
  font-size: 13px;
  white-space: nowrap;
}
.loan-amount {
  padding-right: 30px; 
  font-size: 13px;

  font-weight: bold;
}

.loan-value {
  font-size: 13px;
  font-weight: bold;
  white-space: nowrap;
}

.td2 {
  padding-right: 30px; 
  font-size: 13px;
}

a:hover {
  color: #e44120;
}

.top-left {
  display: inline-flex;
  align-items: center;
  margin: 20px;
  color: #EE4D2D;
}

.top-right {
  position: absolute;
  top: 35px;
  right: 50px;
  font-size: 18px;
}

p.right {
  text-align-last: right;
}
.span0 {
  padding: 3px 10px;
  font-size: 18px;
  text-align: center;
}

.p1 {
  font-size: 13px;
  text-align: center;
}

.p2 {
  font-size: 13px;
  text-align: center;
}


.span1 {
  font-size: 14px;
  font-family: Nunito-SemiBold;
}

.span2 {
  font-size: 20px;
  font-family: Nunito-SemiBold;
  text-align: right;
  font-weight: bold;
}

.span3 {
  font-size: 15px;
  font-family: Nunito-SemiBold;
  text-align: right;
  font-weight: bold;
}
.span4 {
  font-size: 20px;
  font-family: Nunito-SemiBold;
  text-align: right;
  font-weight: bold;
}
.btn {
  background: #EE4D2D;
  color: white;
  font-size: 20px;
  padding: 5px;
  border-radius: 10px;
  border: 0;
  margin: 50px 10px 10px 10px;
  width: 25%;
  height: 60px;
  font-family: Nunito-Regular;
  display: block;
  margin-left: auto;
  margin-right: 0;
  cursor:pointer;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  text-decoration: none;
  overflow: hidden;
}


.btn:hover {
  background: #e44d2f;
  text-decoration: none;
}

.btn:hover {
  background: #e44120;
  text-decoration: none;
}

.container {
  width: 55%;
  margin: auto;
}

.calculator {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 2em;
}

.desc {
  width: 500px;
}

.calc-form {
  display: flex;
  flex-direction: column;
  align-items:center; 
  box-sizing:border-box;
  border: 1px; 
}

.calc-form .form-group {
  width: 100%;
  margin-top: 10px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calc-form .form-group2 {
  width: 100%;
  margin-top: 10px;
  text-align: center;
  display: flex;
  align-items: center;
}

.calc-form label {
  padding: 18px;
  text-align: left;
  width: 30%;
  display: inline-block;
  padding-right: 15%;
}

.calc-form strong {
  font-size: 15px;
  padding-right: 20px;
}


.salary-label {
  padding-right: 10%;
  padding: 20px;
  text-align: left;
  width: 30%;
  display: inline-block;
}

.calc-form input[type='text'] {
  padding: 20px;
  display:inline-block;
  font-size: 20px;
  border-radius: 10px;
  border: 0;
  width: 50%;
}

.special {
  padding-right: 60px;
  font-family: Nunito-SemiBold;
  font-size: 36px;
  color: #EE4D2D;
  
}


.block-wrapper {
  display: flex;
  width: 100%;
}
.block-wrapper-personal{
  display: inline-flex;
  width: 100%;

}

.block {
  width: 50%;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  margin: 0px 0px 0px 5px;
  color: #d6afa7;
}

.block-personal {
  width: 50%;
  padding: 10px;
  border-radius: 20px;
  margin: 0px 0px 0px 2px;
  color: #d6afa7;
}

.small-block {
  font-size: 12px;
  float: left;
  margin: 10px;
}
